import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const servicesData = [
{
    id: 1,
    icon: 'fas fa-camera',
    title: 'Photogrammétrie aérienne ',
    description: "Utilisation de photographies aériennes pour créer des cartes, des modèles 3D et d'autres données spatiales. Cette technologie est employée dans divers domaines tels que la topographie, la cartographie, l'ingénierie, l'architecture, l'archéologie et les sciences environnementales",
    link: "#contact"
},
{
    id: 2,
    icon: 'fa fa-leaf',
    title: 'Smart Farming',
    description: "L'agriculture intelligente utilisant des drones fait référence à l'utilisation de aéronefs sans pilote (UAV) pour collecter des données et des informations destinées à l'agriculture. Les données recueillies par les drones peuvent être utilisées pour améliorer divers aspects de l'agriculture tels que la surveillance des cultures, l'analyse du sol, la gestion de l'irrigation, etc.",
    link: "#contact"
},
{
    id: 3,
    icon: ' fa fa-line-chart',
    title: 'Data Analysis ',
    description: "La collecte et l'analyse de données à l'aide de drones impliquent l'utilisation de aéronefs sans pilote (UAV) pour recueillir des données, qui sont ensuite analysées pour prendre des décisions informées. Cette méthode trouve des applications variées.",
    link: "#contact"
},
{
    id: 3,
    icon: 'fa fa-eye',
    title: 'Aerial Surveys ',
    description: "L'inspection aérienne avec des drones consiste à utiliser des drones (UAV) munis de caméras et d'autres technologies d'imagerie pour collecter des données depuis les airs. Ces données sont ensuite utilisées pour élaborer des cartes, des modèles et d'autres représentations de la zone d'inspection, pouvant être exploitées à diverses fins",
    link: "#contact"
},
{
    id: 3,
    icon: 'fa fa-subway',
    title: 'Smart Cities ',
    description: "La gestion du trafic par des drones fait référence à l'utilisation d'aéronefs sans pilote (UAV) pour surveiller, gérer et contrôler la circulation. L'utilisation de drones pour la gestion du trafic a le potentiel d'améliorer l'efficacité, la sécurité et le flux global de la circulation de diverses manières.",
    link: "#contact"
},
{
    id: 3,
    icon: 'fa fa-industry',
    title: 'Industry Surveys',
    description: "L'inspection industrielle utilisant des drones font référence à l'utilisation d'aéronefs sans pilote (UAV) pour collecter des données dans diverses applications industrielles. Les enquêtes par drone deviennent de plus en plus populaires en raison de leur capacité à fournir une collecte de données économique, précise et sécurisée. Quelques exemples d'industries utilisant des enquêtes par drone incluent.",
    link: "#contact"
}

]
function AppServices() {
    return (
      <section id="services" className="block services-block">
        <Container fluid>
          <div className="title-holder">
            <h2>Nos services</h2>
            <div className="subtitle">Nous vous proposons les services suivants :</div>
          </div>
          <Row>
            {
              servicesData.map(services => {
                return (
                  <Col sm={4} className='holder' key={services.id}>
                    <div className="icon">
                      <i className={services.icon}></i>
                    </div>
                    <h3>{services.title}</h3>
                    <p>{services.description}</p>
                    <a className="btn btn-primary" href={services.link}>Contactez-nous <i className="fas fa-envelope"></i></a>
                  </Col>
                );
              })
            }
          </Row>
          
        </Container>
      </section>
    );
  }
  
  export default AppServices;
