import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ProgressBar from "react-bootstrap/ProgressBar";

import image1 from "../assets/images/image1.jpg";

export default function AppAbout() {
  const photo = 60;
  const farming = 30;
  const cities = 10;
  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>A propos de Nous</h2>
          <div className="subtitle">
            Services and solutions easy to deploy, flexible, eco-friendly and
            cheaper
          </div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={image1} />
          </Col>
          <Col sm={6}>
            <p>
              Chez Dronecate, nous proposons des services et solutions
              professionnels utilisant des drones ; Photogrammétrie aérienne,
              Agriculture intelligente & Agriculture de précision, ainsi que
              Villes intelligentes & Gestion du trafic.
            </p>
            <p>
              Nous fournissons des services faciles à déployer, flexibles,
              respectueux de l'environnement et moins chers.
            </p>
            <div className="progress-block">
              <h4>Photogrammétrie aérienne</h4>
              <ProgressBar
                style={{ backgroundColor: "#808080" }}
                variant="custom"
                now={photo}
                label={`${photo}%`}
              />
            </div>
            <div className="progress-block">
              <h4>Smart Farming & Precision</h4>
              <ProgressBar
                style={{ backgroundColor: "#808080" }}
                variant="custom"
                now={farming}
                label={`${farming}%`}
              />
            </div>
            <div className="progress-block">
              <h4>Smart cities & Traffic management</h4>
              <ProgressBar
                style={{ backgroundColor: "#808080" }}
                variant="custom"
                now={cities}
                label={`${cities}%`}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
