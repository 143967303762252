import React from 'react';
import contactImage from '../assets/images/contact.png';

class AppContact extends React.Component {
  state = {
    nameError: '',
    emailError: '',
    messageError: ''
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    // Validation du nom
    const nameValue = form.name.value.trim();
    if (nameValue.length < 3 || nameValue.length > 20 || !/^[a-zA-Z\s]+$/.test(nameValue)) {
      this.setState({ nameError: 'Veuillez entrer un nom valide (3 à 20 caractères, sans caractères spéciaux)' });
      return;
    } else {
      this.setState({ nameError: '' });
    }

    // Validation de l'email
    const emailValue = form.email.value.trim();
    if (!/\S+@\S+\.\S+/.test(emailValue)) {
      this.setState({ emailError: 'Veuillez entrer une adresse email valide' });
      return;
    } else {
      this.setState({ emailError: '' });
    }

    // Validation du message
    const messageValue = form.message.value.trim();
    if (messageValue.length > 500 || /<[^>]*>|http/.test(messageValue)) {
      this.setState({ messageError: 'Le message ne doit pas dépasser 500 caractères et ne doit contenir ni tags ni liens' });
      return;
    } else {
      this.setState({ messageError: '' });
    }

    fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        apikey: 'e297559b-70cb-4b4e-a0fe-940efe6b4ead',
        name: nameValue,
        email: emailValue,
        message: messageValue,
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('Message envoyé avec succès! Nous vous répondrons dès que possible');
        form.reset();
      } else {
        alert("Echec d'envoi du message!");
      }
    })
    .catch(error => alert('Error: ' + error));
  };

  render() {
    return (
      <section id="contact" className="block contact-block">
        <div className="container contact-container">
          <div className="row">
            <div className="col-md-6">
              <div className="title-holder">
                <h2>Contactez-nous</h2>
                <div className="subtitle">
                  N'hésitez pas à remplir le formulaire ci-dessous pour partager vos suggestions, commentaires ou demandes d'information. Merci de nous écrire, votre feedback est précieux pour améliorer nos services.
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Nom</label>
                  <input type="text" className="form-control" id="name" name="name" required minLength={3} maxLength={20} pattern="^[a-zA-Z\s]+$" placeholder="Votre nom " />
                  {this.state.nameError && <span className="text-danger">{this.state.nameError}</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" id="email" name="email" required placeholder="Votre adresse email" />
                  {this.state.emailError && <span className="text-danger">{this.state.emailError}</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea className="form-control" id="message" name="message" rows="3" required maxLength={500} placeholder="Votre message" />
                  {this.state.messageError && <span className="text-danger">{this.state.messageError}</span>}
                </div>
                <button type="submit" className="btn btn-primary">Envoyer</button>
              </form>
            </div>
            <div className="col-md-6">
              <img src={contactImage} alt="Contact Us" className="img-fluid"/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AppContact;
